@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

input { font-size: 16px!important; }

.radialgrad {
  /* background: radial-gradient(50% 50% at 50% 50%, #0e0e0e78 0%, #02020278 10%, #181614 100%); */
}

.radialgradcenter {
  background: radial-gradient(50% 50% at 50% 50%, #ce83f321 0%, #ce83f321 10%, rgba(20, 43, 114, 0) 100%);
}

.radialgradfoot {
  background: radial-gradient(50% 50% at 50% 10%, #8583f331 0%, #ce83f327 5%, rgba(20, 43, 114, 0) 100%);
}

.radialgradright {
  background: radial-gradient(50% 50% at 100% 50%, #ce83f329 0%, #ce83f327 20%, rgba(20, 43, 114, 0) 100%);
}

.radialgradbuttomright {
  background: radial-gradient(50% 50% at 100% 100%, #8583f31c 0%, #ce83f319 50%, rgba(20, 43, 114, 0) 100%);
}

.radialgradleft {
  background: radial-gradient(50% 50% at 0% 60%, #8583f31c 0%, #ce83f319 20%, rgba(20, 43, 114, 0) 100%);
}

.featuregrad {
  background-color: #141825;
  background-image: linear-gradient(180deg, #141825, #1418255c, #8583f353);
}

@media (max-width: 700px) {
  .radialgradfoot {
    background: radial-gradient(100% 50% at 50% 100%, #8583f331 0%, #ce83f327 5%, rgba(20, 43, 114, 0) 100%);
  }
  .radialgradright {
    background: radial-gradient(100% 50% at 100% 50%, #ce83f329 0%, #ce83f327 20%, rgba(20, 43, 114, 0) 100%);
  }
}

/* Set the color of the track (the area where the thumb doesn't appear) */
/* ::-webkit-scrollbar {
  width: 10px;
} */

/* ::-webkit-scrollbar-track {
  background-color: #8583f30e;
} */

/* Set the color and style of the scrollbar thumb (the draggable handle) */
/* ::-webkit-scrollbar-thumb {
  background-color: #89d7fb47;
  border-radius: 6px;
} */


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden !important;
  background-color: #181614 !important;
  color: white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.nunito {
  font-family: "Nunito", sans-serif;
}

.monts {
  font-family: "Montserrat", sans-serif;
}

.raleway {
  font-family: 'Raleway', sans-serif;
}

.flex-center {
  @apply flex items-center justify-center
}

.bluegrad {
  background-color: #6612fc;
  background-image: linear-gradient(63deg,  #b890fd, #6612fc);
  color: black;
}

.fadetext {
  background-image: linear-gradient(180deg, #ffffff, #efe6ffce, #6812fc69);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
}

.centered-xy {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.bordergrad {
  border: 1px solid #83a6f3;
  color: #83a6f3;
}

.textgrad {
  background-image: linear-gradient(60deg, #89D6FB, #83a6f3, #83a6f3);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
}

.bgblue {
  @apply bg-[#89d6fb]
}

.borderblue {
  @apply border-[#89d6fb]
}

.textblue {
  @apply text-[#89d6fb]
}

.stickyNav {
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 9999 !important;
}

.hotcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 11rem;
  width: 100vw;
  overflow: hidden;
}


.scroll-parent {
  position: relative;
  height: 100%;
  width: 100vw;
  /* max-width: 1200px; */
  margin: auto;
  margin-top: 20px;
  overflow-x: hidden;
}

.scroll-element {
  width: 200vw;
  /* max-width: 2400px; */
  position: absolute;
  left: 0%;
  top: 0%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.scroll-element div {
  width: 100%;
}

.primary {
  animation: primary 20s linear infinite;
}

@keyframes primary {
  from {
    left: 0%;
  }
  to {
    left: -100%;
  }
}

.circle {
  border-radius: 50%;
}

.hero-bg {
  background-image: radial-gradient(circle closest-corner at 60% 50%,
          #ce83f31b 41%,
          #0c0c0c2c);
}

/* MEDIA QUERIES */
@media (max-width: 639px) {
  .radialgrad {
    background: radial-gradient(50% 50% at 50% 50%, #ce83f316 0%, #ce83f316 30%, rgba(20, 43, 114, 0) 100%);
  }
  .hotcontainer {
    padding-inline: 20px;
  }
  .hotPresales {
      grid-auto-columns: 90%;
      gap: 5px;
  }
  .scroll-element {
      width: 500vw;
  }
  @keyframes primary {
      from {
        left: 0%;
      }
      to {
        left: -250%;
      }
    }
}

@media (max-width: 1170px) {
  .hotPresales {
      grid-auto-columns: 15%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-input {
  background-color: #1e1e1e29 !important;
  border: 1px solid #4e4e4e29 !important;
  color: white !important;
  height: 96px !important;
  margin-bottom: 5px;
  font-size: 35px;
  border-radius: 12px !important;
}

.ant-input::placeholder {
  color: #5f6783 !important;
}

.ant-popover-arrow {
  visibility: hidden;
}

.ant-popover-title {
  color: white !important;
}

.ant-popover-inner-content {
  color: white !important;
}

.ant-popover-inner {
  min-width: 260px !important;
  min-height: 140px !important;
  border: 1px solid #21273a;
  background-color: #0e111b !important;
}

.ant-radio-group {
  margin-top: 10px;
}

.ant-radio-button-wrapper {
  background-color: #1f2639 !important;
  color: white !important;
  font-weight: 500;
  border-color: white !important;
}

.ant-radio-button-wrapper-checked {
  border-color: white !important;
  background-color: #363e54 !important;
}

.ant-radio-button-wrapper-checked::before {
  background-color: white !important;
}

.ant-modal-content {
  background-color: #0e111b !important;
  color: white !important;
  padding: 0px !important;
}

.ant-modal {
  border: 1px solid #363e54;
  width: 400px !important;
  border-radius: 10px;
}

.ant-modal-header {
  background-color: #0e111b !important;
}

.ant-modal-title {
  color: white !important;
  padding-top: 17px !important;
  margin-left: 20px !important;
}

.ant-modal-close-icon {
  color: #363e54 !important;
  transition: 0.3s;
}

.ant-modal-close-icon:hover {
  color: white !important;
}

.backersMobile {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 11rem;
  width: 100vw;
  overflow: hidden;
}
.scroll-parent {
  position: relative;
  height: 100%;
  width: 100vw;
  overflow-x: hidden;
}

.scroll-element a {
  width: 100%;
  margin-left: 50px;
}

.primary {
  animation: primary 15s linear infinite;
}

.scroll-element {
  width: 400vw;
  position: absolute;
  left: 0%;
  top: 0%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@keyframes primary {
    from {
        left: 0%;
    }

    to {
        left: -200%;
    }
}

/* MEDIA QUERIES */
@media (max-width: 639px) {
  
  section,
  nav,
  footer {
      overflow: hidden !important;
  }
  .roadCont {
      grid-auto-columns: 60%;
  }
  
  .hero-bg {
      top: -35%;
      right: -50%;
      z-index: 1;
  }
  .scroll-element {
      width: 800vw;
  }
  .primary {
      animation: primary 15s linear infinite;
  }
  @keyframes primary {
      from {
          left: 0%;
      }

      to {
          left: -400%;
      }
  }
}


.roadCont {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 31%;
  gap: 20px;
  overflow-x: auto !important;
  overflow-y: hidden !important;
}

/* MEDIA QUERIES */
@media (max-width: 1170px) {
  .roadCont {
    grid-auto-columns: 60%;
  }
}

@media (max-width: 700px) {
  .roadCont {
    grid-auto-columns: 80%;
  }
  .centered-xm {
    position: absolute;
    left: 50%;
    transform: translateX(-50%)
  }
}

.arrows {
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.modal {
  height: 100vh;
  width: 100vw;
  overflow: hidden !important;
  backdrop-filter: blur(8px);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998
}
.modal::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px); /* Safari support */
  z-index: -1;
}


@import url('https://fonts.googleapis.com/css?family=Lato');
/* .outer {
  height: 100vh;
  min-width: 20vw;
  flex: 1;
} */
.progress {
  display: inline-flex;
  height: 100%;
  padding: 5vh 10%;
}
.progress > div {
  display: flex;
  flex-direction: column;
  color: #333;
}
.progress > div.left {
  padding-right: 20px;
  text-align: right;
}
.progress > div.left div:last-of-type:after {
  display: none;
}
.progress > div.left div:after {
  content: "";
  /* background: rgba(51, 51, 51, 0.1); */
  border-radius: 2px;
  position: absolute;
  right: -20px;
  top: 10px;
  height: 101%;
  width: 1px;
  transform: translateX(50%);
}
.progress > div.right {
  padding-left: 20px;
}
.progress > div.right div.prev:after {
  transition: none;
}
.progress > div.right div.current {
  color: #333;
  font-weight: bold;
}
.progress > div.right div.current:before {
  /* background: #333; */
  padding: 10px;
  transition: all 0.2s 0.15s cubic-bezier(0.175, 0.885, 0.32, 2);
}
.progress > div.right div.current:after {
  height: 0%;
  transition: height 0.2s ease-out;
}
.progress > div.right div.current ~ div {
  color: #666;
}
.progress > div.right div.current ~ div:before {
  /* background: #666; */
  padding: 2.5px;
}
.progress > div.right div.current ~ div:after {
  height: 0%;
  transition: none;
}
.progress > div.right div:before {
  content: "";
  /* background: #333; */
  padding: 5px;
  border-radius: 50%;
  position: absolute;
  left: -20px;
  top: 10px;
  transform: translateX(-50%) translateY(-50%);
  transition: padding 0.2s ease;
}
.progress > div.right div:after {
  content: "";
  /* background: #333; */
  border-radius: 2px;
  position: absolute;
  left: -20px;
  top: 10px;
  height: 101%;
  width: 2px;
  transform: translateX(-50%);
  transition: height 0.2s ease;
}
.progress > div div {
  flex: 1;
  position: relative;
  line-height: 20px;
  cursor: default;
  min-height: 30px;
}
.progress > div div:last-of-type {
  flex: 0;
}
.progress.dark {
  /* background: #333; */
  display: inline-flex;
  height: 100%;
  width: 100%;
  padding: 5vh 10%;
}
.progress.dark > div {
  display: flex;
  flex-direction: column;
  color: #ccc;
}
.progress.dark > div.left {
  padding-right: 20px;
  text-align: right;
}
.progress.dark > div.left div:last-of-type:after {
  display: none;
}
.progress.dark > div.left div:after {
  content: "";
  background: rgba(211, 211, 211, 0.05);
  border-radius: 2px;
  position: absolute;
  right: -20px;
  top: 10px;
  height: 101%;
  width: 1px;
  transform: translateX(50%);
}
.progress.dark > div.right {
  padding-left: 20px;
}
.progress.dark > div.right div.prev:after {
  transition: none;
}
.progress.dark > div.right div.current {
  color: #727272;
  font-weight: bold;
}
.progress.dark > div.right div.current:before {
  background: #727272;
  padding: 10px;
  transition: all 0.2s 0.15s cubic-bezier(0.175, 0.885, 0.32, 2);
}
.progress.dark > div.right div.current:after {
  height: 0%;
  transition: height 0.2s ease-out;
}
.progress.dark > div.right div.current ~ div {
  color: #666;
}
.progress.dark > div.right div.current ~ div:before {
  background: #666;
  padding: 2.5px;
}
.progress.dark > div.right div.current ~ div:after {
  height: 0%;
  transition: none;
}
.progress.dark > div.right div:before {
  content: "";
  background: #484848;
  padding: 8px;
  border-radius: 50%;
  position: absolute;
  left: -20px;
  top: 10px;
  transform: translateX(-50%) translateY(-50%);
  transition: padding 0.2s ease;
}
.progress.dark > div.right div:after {
  content: "";
  background: rgba(211, 211, 211, 0.172);
  border-radius: 2px;
  position: absolute;
  left: -20px;
  top: 10px;
  height: 101%;
  width: 2px;
  transform: translateX(-50%);
  transition: height 0.2s ease;
}
.progress.dark > div div {
  flex: 1;
  position: relative;
  line-height: 20px;
  cursor: default;
  min-height: 30px;
}
.progress.dark > div div:last-of-type {
  flex: 0;
}

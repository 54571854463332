.App {
  text-align: center;
}

:root {
  --dark: #181614;
  --bblue: #89d6fb;
  --ggreen: #89d6fb;
}

.bg-grad {
  /* background-color: #FFB469; */
  background-image: linear-gradient(63deg, #ffb46975, #fff3e821);
}

.t-grad {
  /* background-color: #FFB469; */
  background-image: linear-gradient(63deg, #fae2cb78, #fff3e821);
}

header {
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
}

.logo{
  width: 40px;
  height: 40px;
  padding-right: 20px;
}

.eth{
  width: 20px;
  height: 20px;
  padding-right: 10px;

}

.leftH {
  display: flex;
  align-items: center;
  gap: 20px;
}

.rightH {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.headerItem {
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  font-weight: 500;
  transition: 0.3s;
  display: flex;
  align-items: center;
}

.headerItem:hover {
  background-color: rgb(34, 42, 58);
  cursor: pointer;
}

.connectButton:hover {
  cursor: pointer;
  color: #3b4874
}

.mainWindow {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.link {
  text-decoration: none;
  color: white;
}

.tradeBox {
  background-color: #0E111B;
  border: 2px solid #21273a;
  min-height: 300px;
  width: 100%;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 30px;
  padding-right: 30px;
}


.tradeBoxHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
}

.cog {
  color: #51586f;
  transition: 0.3s;
}

.cog:hover {
  color: white;
  rotate: 90deg;
  cursor: pointer;
}

.switchButton {
  background-color: #3a4157;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 8px;
  position: absolute;
  top: 95px;
  position: absolute;
  left: 50%;
  transform: translate(-50%,-50%);
  color: #5F6783;
  border: 3px solid #0E111B;
  font-size: 12px;
  transition: 0.3s
}

.dollarAmount {
  position: absolute;
  top: 75px;
  left: 14px;
  font-size: 10px;
  color: #5F6783;
}

.switchButton:hover {
  color: white;
  cursor: pointer;
}

.inputs {
  position: relative;
}

.assetOne {
  position: absolute;
  min-width: 50px;
  height: 30px;
  background-color: #3a4157;
  top: 36px;
  right: 20px;
  border-radius: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  font-size: 17px;
  padding-right: 8px;
}

.assetTwo {
  position: absolute;
  min-width: 50px;
  height: 30px;
  background-color: #3a4157;
  top: 135px;
  right: 20px;
  border-radius: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  font-size: 17px;
  padding-right: 8px;
}

.assetOne:hover{
  cursor: pointer;
}

.assetTwo:hover{
  cursor: pointer;
}

.assetLogo {
  height: 22px;
  margin-left: 5px;
}

.swapButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100%);
  height: 55px;
  font-size: 20px;
  border-radius: 12px;
  color: white;
  font-weight: bold;
  transition: 0.3s;
  margin-bottom: 30px;
  margin-top: 25px;
}

.swapButton[disabled] {
  opacity: 0.2;
  color: black;
}

.swapButton[disabled]:hover {
  cursor: not-allowed;
  background-color: #EC851E;
}

.swapButton:hover {
  cursor: pointer;
  background-color: #EC851E
}

.tokenLogo {
  height: 40px;
  width: 40px;
}

.modalContent {
  border-top: 1px solid #363e54;
  /* padding-top: 20px; */
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px
}

.tokenChoice {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.tokenChoice:hover {
  cursor: pointer;
  background-color: #1f2639;
}

.tokenName {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
}

.tokenTicker {
  margin-left: 10px;
  font-size: 13px;
  font-weight: 300;
  color: #51596f;
}


/* ROADMAP */
div.roadmap--container {
  overflow-y: hidden;
  padding: 0 !important;
  margin-top: 4rem;
  display: flex;
  align-items: start;
}
div.indicator--container * {
  transition: all linear 0.1s;
}

div.indicator--container {
  margin-bottom: 0;
  /* padding-block: 40px; */
}
div.indicator--container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 1.9rem;
  height: 100%;
  width: 0.2rem;
  background-color: var(--ggreen);
}
div.indicator--container > div.indicator {
  font-size: 1.6rem;
  font-weight: bold;
  padding-left: 6rem;
  padding-top: 0.8rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: 0.1s all ease-in;
  -o-transition: 0.1s all ease-in;
  transition: 0.1s all ease-in;
  white-space: nowrap;
}
div.indicator--container > div.indicator.active-txt {
  font-size: 2rem;
}
div.indicator--container > div.indicator:not(:first-child) {
  margin-top: 5rem;
}

div.indicator--container > div.indicator span {
  position: absolute;
  /* top: 0; */
  left: 0;
  width: 4rem;
  height: 4rem;
  background: none;
  border: 5px solid var(--ggreen);
  background-color: var(--dark);
  border-radius: 50%;
  -webkit-transition: 0.1s all ease-in;
  -o-transition: 0.1s all ease-in;
  transition: 0.1s all ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.6);
  z-index: 9999999999 !important;
}
div.indicator--container > div.indicator span.active-btn {
  transform: scale(1);
}
div.indicator--container > div.indicator span::after {
  content: "";
  position: relative;
  top: 0;
  left: 0;
  width: 1.5rem;
  height: 1.5rem;
  background: var(--ggreen);
  border-radius: 50%;
  -webkit-transition: 0.1s all ease-in;
  -o-transition: 0.1s all ease-in;
  transition: 0.1s all ease-in;
  z-index: 9999999999 !important;
}

div.roadmap-content-container {
  position: relative !important;
  width: auto;
  min-width: 70%;
  height: 100%;
}

div.roadmap-content-container > .content--container {
  display: grid;
  padding-inline: 50px;
  padding-block: 50px;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 30px;
  column-gap: 20px;
  border-radius: 20px;
  border: 1px solid var(--ggreen);
  /* transform: translateY(20px);
  opacity: 0; */
}
div.roadmap-content-container > .content--container.active {
  transform: translateY(0px);
  opacity: 1;
}
div.roadmap-content-container > .content--container.inactive {
  transform: translateY(20px);
  opacity: 0;
}
div.roadmap-content-container > .completed-roadmap {
  display: grid;
}
div.roadmap-content-container > .inprogress-roadmap {
  display: none;
  grid-template-columns: repeat(1, 1fr);
}
div.roadmap-content-container > .upcoming-roadmap {
  display: none;
  grid-template-columns: repeat(1, 1fr);
}
div.roadmap-content-container > .content--container > .content {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 700;
}
div.roadmap-content-container > .content--container > .content p {
  padding-left: 20px;
}

div.roadmap-content-container > .content--container > .content > svg {
  width: 20px;
  height: 20px;
}
.rd-general-1 {
  display: none;
}
@media screen and (max-width: 992px) {
  .rd-general {
    display: flex;
    max-width: 100% !important;
  }
  section.rd-general h1.header {
    overflow: visible;
  }
  /* ROADMAP */
  div.roadmap--container {
    width: 90% !important;
    overflow: hidden;
    padding: 0 !important;
    margin-top: 4rem;
    display: flex;
    align-items: start;
    flex-direction: column;
  }
  div.indicator--container * {
    transition: all linear 0.1s;
  }

  div.indicator--container {
    margin-bottom: 0;
    padding-block: 40px;
    display: flex;
    width: 100%;
  }
  div.indicator--container::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 0.2rem;
    width: 100%;
    z-index: -1;
    margin-top: 2rem;
    background-color: var(--ggreen);
  }
  div.indicator--container > div.indicator {
    font-size: 1rem !important;
    font-weight: bold;
    padding-left: 0;
    padding-top: 0.8rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: 0.1s all ease-in;
    -o-transition: 0.1s all ease-in;
    transition: 0.1s all ease-in;
    white-space: nowrap;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  div.indicator--container > div.indicator.active-txt {
    font-size: 1.6rem !important;
  }
  div.indicator--container > div.indicator:not(:first-child) {
    margin-top: 0rem;
  }

  div.indicator--container > div.indicator span {
    position: absolute;
    top: -2rem;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    width: 3rem;
    height: 3rem;
    background: none;
    border: 5px solid var(--ggreen);
    background-color: var(--dark);
    border-radius: 50%;
    -webkit-transition: 0.1s all ease-in;
    -o-transition: 0.1s all ease-in;
    transition: 0.1s all ease-in;
    z-index: 9999999999 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0.6);
  }
  div.indicator--container > div.indicator:nth-child(2) span {
    position: absolute;
    right: 100% !important;
    margin: 0 40%;
  }
  div.indicator--container > div.indicator span.active-btn {
    transform: scale(1);
  }
  div.indicator--container > div.indicator span::after {
    content: "";
    position: relative;
    top: 0;
    left: 0;
    width: 1rem;
    height: 1rem;
    background: var(--ggreen);
    border-radius: 50%;
    -webkit-transition: 0.1s all ease-in;
    -o-transition: 0.1s all ease-in;
    transition: 0.1s all ease-in;
    z-index: 9999999999 !important;
  }

  div.roadmap-content-container {
    position: relative !important;
    width: auto;
    min-width: 70%;
    height: 100%;
    max-width: 100%;
  }

  div.roadmap-content-container > .content--container {
    display: grid;
    padding-inline: 20px;
    padding-block: 20px;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 10px;
    column-gap: 10px;
    border-radius: 20px;
    border: 1px solid var(--ggreen);
    width: 100%;
    /* transform: translateY(20px);
  opacity: 0; */
  }
  div.roadmap-content-container > .content--container.active {
    transform: translateY(0px);
    opacity: 1;
  }
  div.roadmap-content-container > .content--container.inactive {
    transform: translateY(20px);
    opacity: 0;
  }
  div.roadmap-content-container > .completed-roadmap {
    display: grid;
  }
  div.roadmap-content-container > .inprogress-roadmap {
    display: none;
    grid-template-columns: repeat(1, 1fr);
  }
  div.roadmap-content-container > .upcoming-roadmap {
    display: none;
    grid-template-columns: repeat(1, 1fr);
  }
  div.roadmap-content-container > .content--container > .content {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 700;
  }
  div.roadmap-content-container > .content--container > .content p {
    padding-left: 10px;
  }

  div.roadmap-content-container > .content--container > .content > svg {
    width: 14px;
    height: 14px;
  }
}